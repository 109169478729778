<template>
  <div class="uploadBigBox">
    <div class="groupBox">
      <FindGroup :data="data" @resultGroup="resultGroup" ref="FindGroup"></FindGroup>
    </div>
    <div class="uploadBox">
      <div class="dragArea">
        <p>拖拽图片到本区域上传</p>
        <p>或点击上传</p>
        <div class="addBox">
          <i class="el-icon-plus"></i>
        </div>
        <input @input="uploadInput" type="file" :multiple="true" ref="addBox" class="uploadInput" />
      </div>
      <el-dialog
        :visible.sync="progressDialog"
        width="800"
        modal-append-to-body
        append-to-body
        @close="clearData"
      >
        <template #title>
          <div>
            正在上传（<span style="color: #409eff">{{ nowUploadList.length }}</span
            >/{{ uploadList.length }}）
          </div>
        </template>
        <template>
          <ShowProgress
            @chooseSuccess="chooseSuccess"
            ref="showP"
            :showProgressList="showProgressList"
            :groupId="nowChooseClassId.id"
          ></ShowProgress>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import ShowProgress from './showProgress.vue'
import FindGroup from '@/components/lookGroup.vue'
import { getfilesize } from '@/util/util.js'
export default {
  props: {
    nowChooseClassId: {
      type: Object,
      default: () => {
        return {
          id: -1,
          name: '全部图片'
        }
      }
    },
    data: {
      type: Array,
      default: () => []
    }
  },
  components: {
    ShowProgress,
    FindGroup
  },

  data() {
    return {
      uploadList: [],
      showProgressList: [],
      progressDialog: false
    }
  },
  computed: {
    nowUploadList() {
      return this.showProgressList.filter((item) => item.percentage === 100 && !item.error)
    }
  },

  methods: {
    chooseSuccess() {
      this.$emit('chooseSuccess')
    },
    findGroupFn() {
      this.$refs.FindGroup.setDefaule(this.nowChooseClassId)
    },
    resultGroup(item) {
      this.nowChooseClassId = item
    },
    uploadInput() {
      this.uploadList = []
      let files = this.$refs.addBox.files
      for (const key in files) {
        if (!isNaN(Number(key))) {
          this.uploadList.unshift(files[key])
        }
      }
      let arr = ['.png', '.jpg', '.jpeg', '.gif']
      // 检索上传文件的后缀名是否符合格式
      for (let index = this.uploadList.length - 1; index >= 0; index--) {
        let str = this.uploadList[index].name.lastIndexOf('.')
        let res = arr.findIndex((item1) => item1 == this.uploadList[index].name.substr(str))
        if (res == -1) {
          this.$message({
            message: `${this.uploadList[index].name}不符合上传类型，已从上传队列删除`,
            type: 'error',
            customClass: 'mzindex'
          })
          this.uploadList.splice(index, 1)
          continue
        }
        let maxSize = 1024 * 1024 * 5
        if (this.uploadList[index].size > maxSize) {
          this.$message({
            message: `${this.uploadList[index].name}超过最大传输限制，已从上传队列删除`,
            type: 'error',
            customClass: 'mzindex'
          })
          this.uploadList.splice(index, 1)
          continue
        }
        this.showProgressList.push({
          name: this.uploadList[index].name,
          size: getfilesize(this.uploadList[index].size),
          percentage: 0,
          error: false,
          errorDetail: '',
          file: this.uploadList[index]
        })
      }
      if (this.showProgressList.length > 0) {
        this.progressDialog = true
        this.$refs.addBox.value = ''
        this.$nextTick(() => {
          this.$refs.showP.launchUpload()
        })
      }
    },

    clearData() {
      this.showProgressList = []
    }
  }
}
</script>

<style lang="less" scoped>
.uploadBigBox {
  display: flex;
}
.uploadBox {
  flex: 1;
  height: 100%;
  .dragArea {
    width: 100%;
    height: 400px;
    border: 1px dashed #e3e4e6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #979899;
    font-size: 16px;
    position: relative;
    p {
      line-height: 24px;
    }
    .addBox {
      margin-top: 24px;
      width: 80px;
      height: 80px;
      background: #f8f9fb;
      display: flex;
      justify-content: center;
      align-items: center;
      i {
        color: #cacbcc;
        font-size: 20px;
      }
    }
    .uploadInput {
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }
}
</style>
